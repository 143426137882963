module.exports = {
  siteTitle: "Dewen Li", // <title>
  shortSiteTitle: "DL", // <title> ending for posts and pages
  siteDescription: ".",
  siteUrl: "http://dewen.li",
  slogan: "Welcome 欢迎 Grato رحب",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Dewen Li",
  authorTwitterAccount: "dewen",

  /* info */
  headerTitle: "Dewen Li",
  headerSubTitle: "A site about Dewen",

  /* manifest.json */
  manifestName: "HeroBlog - a GatsbyJS starter",
  manifestShortName: "HeroBlog", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "https://s.gravatar.com/avatar/793696e9d221f876fe0cea0793124808?s=80",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/dewen" },
    { name: "twitter", url: "https://twitter.com/dewenli" },
    { name: "facebook", url: "http://facebook.com/dewenli" }
  ]
};
